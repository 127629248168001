import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import {object, string} from "yup";
import {signInWithEmailAndPassword} from "firebase/auth";
import {auth} from "../firebase";
import {navigate} from "gatsby";

type RouterItemProps = {
  path?: string
}

interface LoginCredential {
  username: string,
  password: string
}

const schema = object({
  username: string().required(),
  password: string().required(),
}).required();

function AdminLogin({path}: RouterItemProps) {
  const [error, setError] = useState();
  const { register, handleSubmit, formState: { errors } } = useForm<LoginCredential>({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data: LoginCredential) => {
    signInWithEmailAndPassword(auth, data.username, data.password).then(userCredential => {
      navigate("/admin/");
    }).catch(error => {
      setError(error.message);
      console.log(error);
    });
  };

  return (
    <>
      {error}
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="text" {...register("username", {required: true})} />
        <input type="password" {...register("password", {required: true})} />
        <button type="submit">Login</button>
      </form>
    </>
  );
}

export default AdminLogin;