import React, {Component, useEffect} from "react";
import {useAuthContext} from "./AuthProvider";
import {navigate} from "gatsby";

type PrivateRouteProps<P> = {
  component: React.ComponentType<P>
  path?: string,
  type?: string
}

function PrivateRoute<P>({component: Component, ...rest}: PrivateRouteProps<P>) {
  const {isLoading, user} = useAuthContext();

  useEffect(() => {
    if (!isLoading) {
      if (!user) {
        navigate("/admin/login");
        return;
      }
    }
  }, [isLoading, user])

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // @ts-ignore
  return <Component {...rest} />;
}

export default PrivateRoute;