import React, {useEffect, useState} from "react"
import { Router } from "@reach/router"
import {collection, query, where, getDocs, QuerySnapshot, DocumentData, doc, getDoc} from "firebase/firestore";
import {signOut} from "firebase/auth";

import AuthProvider from "../../containers/AuthProvider";
import PrivateRoute from "../../containers/PrivateRoute";
import AdminLogin from "../../containers/AdminLogin";
import {Order} from "../../models/Order";
import {firestore, auth} from "../../firebase";
import {HeadFC, Link, navigate} from "gatsby";

type RouterItemProps = {
  path?: string
}

function AdminLayout({children}: {children: JSX.Element|string|Array<JSX.Element|string>}): JSX.Element {
  return (
    <>
      <nav className="navbar bg-light navbar-expand-sm">
        <div className="container">
          <Link className="navbar-brand" to="/admin/">WD</Link>
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <Link activeClassName="active" className="nav-link" to="/admin/">In Review</Link>
              </li>
              <li className="nav-item">
                <Link activeClassName="active" className="nav-link" to="/admin/available/">Available</Link>
              </li>
            </ul>
            <div className="d-flex">
              <button className="btn btn-primary" style={{minHeight: 0}} onClick={() => {
                signOut(auth).then(() => {
                  navigate("/admin/login");
                });
              }}>Sign out</button>
            </div>
          </div>


        </div>
      </nav>
      {children}
    </>
  );
}

type AdminIndexType = {
  type?: "review" | "available"
}

function AdminIndex({type = "review"}: AdminIndexType): JSX.Element {
  const [orders, setOrders] = useState<Array<any>>([]);

  useEffect(() => {
    const col = collection(firestore, "orders");
    const q = query(col, where("state", "==", type));
    getDocs(q).then(snapshots => {
      const newOrders:Array<any> = [];
      snapshots.forEach(snapshot => {
        const data = snapshot.data();
        data.id = snapshot.id;
        newOrders.push(data);
      });
      setOrders(newOrders);
    });
  }, []);

  return (
    <AdminLayout>
      <div className="container mt-5">
        <div className="list-group">
          {orders.map(order => {
            return (
              <Link to={`/admin/order/${order.id}`} className="list-group-item">
                <div className="d-flex justify-content-between">
                  <div>
                    {order.createdAt.toDate().toLocaleString()}<br />
                    <small>{order.id}</small>
                  </div>
                  <div>
                    <span className="badge bg-primary">{order.state}</span>
                  </div>
                </div>
              </Link>
            );
          })}

          {orders.length == 0 && (
            <div className="card">
              <div className="card-body p-5 text-center text-muted">
                No item to review
              </div>
            </div>
          )}
        </div>
      </div>
    </AdminLayout>
  )
}

type AdminOrderProps = RouterItemProps & {
  id?: string
}

function AdminOrder({id}: AdminOrderProps) {
  const [order, setOrder] = useState<Order|null>(null);

  useEffect(() => {
    const docRef = doc(firestore, "orders", id!);
    getDoc(docRef).then((snapshot) => {
      if (snapshot.exists()) {
        const _order = snapshot.data() as Order;
        setOrder(_order);
      }
    });
  }, [id]);

  if (!order) {
    return (
      <div>Loading...</div>
    );
  }

  return (
    <AdminLayout>
      <div className="container">
        <div className="form-table">
          <div className="form-row">
            <div className="form-row-label">State</div>
            <div className="form-row-control">{order.state}</div>
          </div>
          <div className="form-row">
            <div className="form-row-label">年齢</div>
            <div className="form-row-control">{order.age}歳</div>
          </div>
          <div className="form-row">
            <div className="form-row-label">身長</div>
            <div className="form-row-control">{order.height}cm</div>
          </div>
          <div className="form-row">
            <div className="form-row-label">体重</div>
            <div className="form-row-control">{order.weight}kg</div>
          </div>
          <div className="form-row">
            <div className="form-row-label">ワキ脱毛の有無</div>
            <div className="form-row-control">{order.hair}</div>
          </div>
          <div className="form-row">
            <div className="form-row-label">お顔を含めた撮影</div>
            <div className="form-row-control">{order.face}</div>
          </div>
          <div className="form-row">
            <div className="form-row-label">トップレスでの撮影</div>
            <div className="form-row-control">{order.topless}</div>
          </div>
          <div className="form-row">
            <div className="form-row-label">動画の公開</div>
            <div className="form-row-control">{order.publish}</div>
          </div>
          <div className="form-row">
            <div className="form-row-label">動画の販売</div>
            <div className="form-row-control">{order.sales}</div>
          </div>
          <div className="form-row">
            <div className="form-row-label">報酬受け取り方法</div>
            <div className="form-row-control">{order.payment}</div>
          </div>
          <div className="form-row">
            <div className="form-row-label">メールアドレス</div>
            <div className="form-row-control">{order.email}</div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

function AdminPage({path}: RouterItemProps): JSX.Element {
  return (
    <AuthProvider>
      <Router basepath="/admin">
        <AdminLogin path="/login" />
        <PrivateRoute path="/order/:id" component={AdminOrder}/>
        <PrivateRoute path="/available" component={AdminIndex} type={"available"} />
        <PrivateRoute path="/" component={AdminIndex} />
      </Router>
    </AuthProvider>
  );
}

export const Head: HeadFC = ({data}) => (
  <title>WD Backend</title>
);

export default AdminPage;