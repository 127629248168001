import React, {useContext, createContext, useState, useEffect} from "react";
import { auth } from "../firebase";
import { onAuthStateChanged, User } from "firebase/auth";
import {ParsedToken} from "firebase/auth";

type AuthState = {
  isLoading: boolean,
  user: User|null,
  claims: ParsedToken|null
};

const AuthContext = createContext<AuthState>({isLoading: true, user: null, claims: null});

export function useAuthContext() {
  return useContext(AuthContext);
}

function AuthProvider({children}: {children: JSX.Element}) {
  const [user, setUser] = useState<User|null>(auth.currentUser);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [claims, setClaims] = useState<ParsedToken|null>(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user);

      if (user) {
        user.getIdTokenResult().then(result => {
          setClaims(result.claims);
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    });
    // return () => {
    //   setUser(null);
    //   setLoading(false);
    // };
  }, []);

  return <AuthContext.Provider value={{isLoading, user, claims}}>{children}</AuthContext.Provider>;
}

export default AuthProvider;